// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyB3aRrF8Mak3AC1ladVxxlCek8lchLAqGA',
    authDomain: 'neus-5e93d.firebaseapp.com',
    projectId: 'neus-5e93d',
    storageBucket: 'neus-5e93d.appspot.com',
    messagingSenderId: '653383184611',
    appId: '1:653383184611:web:980a6e92e7380f40c495d6'
  },
  breakpoints: {
    large: 992,
    medium: 768,
    small: 576,
  },
  servicesName: [
    'Paquete THIN',
    'Paquete MEDIUM',
    'Paquete THIN',
    'Paquete NERD',
    'Paquete GEEK',
    'Paquete ENTERPRISE',
  ],
  contactFormCollection: 'contactForms',
  supportEmail: 'support@neus.agency'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
