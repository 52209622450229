import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {Router} from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit, AfterViewInit{

  @Input() breakPoint: number =  992;
  @Input() width: number;
  @Input() height: number;
  @Input() minMobileHeight = 800;
  isBiggerThanBreakpoint: boolean;
  innerWidth;
  innerHeight;
  rowNgStyle: any;
  titleSectionNgStyle: any;
  imageNgStyle: any;
  documentLocal: Document;

  @ViewChild('mobileSection') mobileSection: ElementRef;


  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router
  ) {
    this.documentLocal = document;
    router.events.subscribe((val) => {
      console.log('Cambio');
      this.setRowBackgroundColor();
    });
  }

  ngOnInit(): void {
    this.setProperties();
  }

  ngAfterViewInit(): void {
    console.log('AFTERVIEWINIT');
    this.setRowBackgroundColor();
  }

  getLastBodySectionBackgroundColor(): string {
    const elements = this.documentLocal.getElementsByClassName('body-section');
    if (elements.length > 0) {
      const lastBodySectionhtmlElement = elements.item(elements.length - 1) as HTMLElement;
      console.log(lastBodySectionhtmlElement.style);
      return lastBodySectionhtmlElement.style.backgroundColor;
    }
    return null;
  }

  setRowBackgroundColor(): void {
    if (this.mobileSection !== undefined) {
      console.log('FOOTER: Diferente de undefined');
      console.log(this.getLastBodySectionBackgroundColor());
      this.mobileSection.nativeElement.style.backgroundColor = this.getLastBodySectionBackgroundColor();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.setRowBackgroundColor();
    this.setProperties();
  }

  setProperties(): void {
    this.setInnerWidth();
    this.setInnerHeight();
    this.setIsBiggerThanBreakpoint();
    this.setRowStyle();
  }

  setInnerWidth(): void {
    this.innerWidth = window.innerWidth;
  }

  setInnerHeight(): void {
    this.innerHeight = window.innerHeight >= this.minMobileHeight ? window.innerHeight : this.minMobileHeight;
  }

  setIsBiggerThanBreakpoint(): void {
    this.isBiggerThanBreakpoint = this.innerWidth >= this.breakPoint;
  }

  setRowStyle(): any {
    this.rowNgStyle = {
      'height.px': this.innerHeight  * 0.25,
    };
  }
}
