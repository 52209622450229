import { Injectable } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {ContactForm} from '../../../interfaces/contactForm';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactFormService {

  constructor(private firestore: AngularFirestore) { }

  sendContactForm(contactForm: ContactForm): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        if (contactForm.id === '' || contactForm.id === null || contactForm.id === undefined ) {
          contactForm.id = this.firestore.createId();
        }
        await this.firestore.collection(environment.contactFormCollection).doc(contactForm.id).set(contactForm);
        resolve(true);
      } catch (e) {
        console.log(e);
        resolve(false);
      }
    });
  }
}
