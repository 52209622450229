<div *ngIf="isBiggerThanBreakpoint ; else elseBlock" class="row footer">
  <div class="col-md-4 footer-section">
    <div class="footer-left-section d-flex align-items-center justify-content-center">
      <img src="assets/images/Asset%203.png" height="70px" alt="logo neus"/>
    </div>
  </div>
  <div class="col-md-4 footer-section">
    <div class="footer-middle-section d-flex flex-column align-items-center justify-content-center">
      <div class="d-flex" style="margin-bottom: 20px">
        <img src="assets/images/Asset%204.png" style="margin-right: 20px" height="50px"/>
        <img src="assets/images/Asset%205.png"  style="margin-left: 20px" height="50px"/>
      </div>
      <div class="d-flex">
        <p class="footer-text">© 2021 by neus</p>
      </div>
    </div>
  </div>
  <div class="col-md-4 footer-section">
    <div class="footer-right-section d-flex align-items-center justify-content-center">
      <div class="col-md-9">
        <div class="row justify-content-center" style="margin-bottom: 20px">
          <div class="col-md-5 footer-links">
            <a class="nav-link" routerLink="/inicio" routerLinkActive="active">INICIO</a>
          </div>
          <div class="col-md-5 footer-links">
            <a class="nav-link" routerLink="/servicios" routerLinkActive="active">SERVICIOS</a>
          </div>
        </div>
        <div class="row  justify-content-center">
          <div class="col-md-5 footer-links">
            <a  class="nav-link" routerLink="/nosotros" routerLinkActive="active">NOSOTROS</a>
          </div>
          <div class="col-md-5 footer-links">
            <a class="nav-link" routerLink="/contacto" routerLinkActive="active">CONTACTO</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #elseBlock>
  <div #mobileSection class="row" [ngStyle]="rowNgStyle">
    <div class="col-md-12 footer d-flex">
      <div class="col-md-4 d-flex flex-column align-items-center justify-content-center">
        <div class="d-flex flex-column">
          <img src="assets/images/Asset%204.png" class="social-icon" />
          <img src="assets/images/Asset%205.png"  class="social-icon"/>
        </div>
      </div>
      <div class="col-md-4 d-flex flex-column align-items-center justify-content-center">
        <div class="logo-section ">
          <img src="assets/images/Asset%203.png" class="logo" alt="logo neus"/>
        </div>
        <p class="footer-text">© 2021 by neus</p>
      </div>
      <div class="col-md-4 d-flex align-items-center justify-content-center">

      </div>
    </div>
  </div>
</ng-template>

