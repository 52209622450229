import {Component, Input, OnInit, Output} from '@angular/core';
import {EventEmitter} from 'events';

@Component({
  selector: 'app-rounded-button',
  templateUrl: './rounded-button.component.html',
  styleUrls: ['./rounded-button.component.css']
})


export class RoundedButtonComponent implements OnInit {

  @Input() text: string;
  @Input() onHoverBackgroundColor: string;
  @Input() onHoverTextColor: string;
  @Input() backgroundColor: string;
  @Input() textColor: string;
  @Input() fontSize: Map<string, string>;
  ngStyle: any;

  constructor() { }

  ngOnInit(): void {
    this.setNgStyle();
  }

  setNgStyleOnhover(): any {
    this.ngStyle =  {
      'background-color': this.onHoverBackgroundColor,
      color: this.onHoverTextColor,
      ...this.fontSize
    };
  }

  setNgStyle(): any {
    this.ngStyle = {
      'background-color': this.backgroundColor,
      color: this.textColor,
      ...this.fontSize
    };
  }

}
