<nav class="navbar navbar-expand-lg fixed-top header">
  <a class="navbar-brand logo" href="#">
    <img src="/assets/images/logo.png" width="50" height="50" alt="">
  </a>
  <button class="navbar-toggler custom-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
    <ul class="navbar-nav menu">
      <li class="nav-item">
        <a class="nav-link menu-text" routerLink="/inicio" routerLinkActive="active">INICIO</a>
      </li>
      <li class="nav-item">
        <a class="nav-link  menu-text" routerLink="/servicios" routerLinkActive="active">SERVICIOS</a>
      </li>
      <li class="nav-item">
        <a class="nav-link  menu-text" routerLink="/nosotros" routerLinkActive="active">NOSOTROS</a>
      </li>
      <li class="nav-item">
        <a class="nav-link  menu-text" routerLink="/contacto" routerLinkActive="active">CONTACTO</a>
      </li>
    </ul>
  </div>
</nav>
