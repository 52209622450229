import {Component, HostListener, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-main-title-section',
  templateUrl: './main-title-section.component.html',
  styleUrls: ['./main-title-section.component.scss']
})
export class MainTitleSectionComponent implements OnInit {

  @Input() breakPoint: number;
  @Input() width: number;
  @Input() height: number;
  @Input() minMobileHeight = 800;
  @Input() headerHeight = 76;
  @Input() titulo: string;
  isBiggerThanBreakpoint: boolean;
  innerWidth;
  innerHeight;
  rowNgStyle: any;

  constructor() { }

  ngOnInit(): void {
    this.setProperties();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.setProperties();
  }

  setProperties(): void {
    this.setInnerWidth();
    this.setInnerHeight();
    this.setIsBiggerThanBreakpoint();
    this.setRowStyle();
  }

  setInnerWidth(): void {
    this.innerWidth = this.width;
  }

  setInnerHeight(): void {
    this.innerHeight = window.innerHeight >= this.minMobileHeight ? window.innerHeight - this.headerHeight : this.minMobileHeight - this.headerHeight;
    console.log(this.innerHeight);
  }

  setIsBiggerThanBreakpoint(): void {
    this.isBiggerThanBreakpoint = this.innerWidth >= this.breakPoint;
  }

  setRowStyle(): any {
    this.rowNgStyle = {
      'height.px': this.innerHeight,
    };
  }


}
