import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ContactFormService} from './services/contactForm/contact-form.service';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    // Todos los servicios con patron sigleton van aqui
    ContactFormService
  ]
})
export class CoreModule { }
